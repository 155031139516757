import styled from 'styled-components';

export const Container = styled.section`
    background: #FFF;
    height: 690px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    
    @media (max-width: 768px) {
      z-index: 1;
      height: auto;
      
      grid-template-columns: 100%;

      max-width: 375px;
    }
    
`;

export const Title = styled.div`
  
    align-self:center;
    justify-self:center;
    max-width: 35vh;
    max-height: 40vh;
    top: 30vh;
    left: 9vh;

    h1 {
        font-family: "Avenir", Avenir, Montserrat;;
        font-style: normal;
        font-weight: 900;
        font-size: 56px;
        line-height: 56px;
        color: #0E222B;
    }

    p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: #ADB5BD;
    }

    @media (max-width: 768px) {
      max-width: 282px;
      margin-left: 5px ;
      margin-top: 275px;

        h1 {
            font-size: 40px;
        }
        
        p {
            margin-right: 10px;
        }
    }

    @media (max-width: 375px) {
        
      margin-top: 255px;
    }
    
`;

export const Icon = styled.div`

    background: #80F5E4;
    border-radius: 32px;
    width: 99px;
    height: 99px;
    text-align: center;
    margin-bottom: 2vh;

    svg{
        position:relative;
        top: calc(50% - 20px);
    }
`;

export const Images = styled.div`
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    position: absolute; 
    
`;

export const PhoneWrap = styled.img`
   width:21%;
   position: absolute;
   top: 19vh;
   left: 55%;
   

   @media (max-width: 500px) {
        width:60%;
        left: 13vh;
        top: 4vh;
   }

   @media (max-width: 375px) {
    left: 13vh;
   }
`;

export const ImageWrap = styled.img`
    
    margin: 10vh auto 0;
    float:right;
    
    @media (max-width: 768px) {
     display: none;
    }
`;


import React from 'react';

import { FiRotateCcw } from 'react-icons/fi';

import { Container, 
         Title, 
         Icon, 
         Images,
         PhoneWrap,
         ImageWrap } from './styles';

import AnticipationImage from '../../images/gatsby-anticipation-image.png';
import AnticipationPhoneImage from '../../images/gatsby-anticipation-phone.png';

const Anticipation = () => {
    return (
        <>
        <Container id="antecipation">
            
            <Title>
                
                <Icon>
                    <FiRotateCcw size={40} />
                </Icon>

                <h1>Antecipação</h1>
                <p>Com o app o corretor pode antecipar sua comissão à uma taxa justa.</p>
            
            </Title>

            <Images>

                <PhoneWrap  src={AnticipationPhoneImage} />
                
                <ImageWrap src={AnticipationImage} />
            </Images>
        </Container>
        </>       
    )
}

export default Anticipation